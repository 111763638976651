import React from "react";

export default function Grid({ children }) {
  return <div className="grid m-0">{children}</div>;
}

export function Col({ size, children, extraClassNames }) {
  return (
    <div
      className={
        size ? `col-${size} ${extraClassNames}` : `col ${extraClassNames}`
      }
    >
      {children}
    </div>
  );
}
