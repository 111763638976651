import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCustomerAction } from "store/actions/CustomerActions";
import { showFormErrors } from "utils/commonFunctions";
import formValidation from "utils/validations";

const CustomerCotainer = () => {
    const [data, setData] = useState({
        email: "",
        firstName: "",
        lastName: "",
    })
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                addCustomerAction(
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        navigate('/customers/list')
                    }
                )
            )
        }
    }

    return {
        data,
        handleChange,
        loading,
        onSubmit,
        navigate,
    }
}

export default CustomerCotainer