import React from "react";
import { ReactComponent as Logo } from "assets/images/svg/Logo.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logout } from "assets/images/svg/Logout.svg";
import { confirmDialog } from "primereact/confirmdialog";
import { logout } from "services/auth";
import { sidebarItems } from "./SidebarRoutes";
import SidebarItem from "./SidebarItem";

export default function Sidebar() {
  const navigate = useNavigate();

  const onLogout = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      className: "invitation-modal",
      header: "Confirmation",
      rejectClassName: "primary-button-outlined bg-transparent",
      acceptClassName: "primary-button",
      icon: "pi pi-sign-out",
      defaultFocus: "accept",
      accept: () => {
        logout(() => navigate("/"));
      },
    });
  };

  return (
    <div className="layout-sidebar">
      <div className="logo">
        <Logo />
        <div>IVO</div>
      </div>
      <div className="menu-bar">
        <div className="sidebar-header">
          {sidebarItems.map((item, i) => (
            <SidebarItem
              Svg={item?.icon}
              title={item?.title}
              items={item?.items}
              link={item?.link}
            />
          ))}
        </div>
        <div className="sidebar-footer">
          <SidebarItem Svg={Logout} title="Logout" onClick={onLogout} />
        </div>
      </div>
    </div>
  );
}
