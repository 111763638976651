import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setCustomerSlice: (state, action) => {
            state.customer = action.payload
        },
    },

});

export const { setCustomerSlice } = customerSlice.actions;
export default customerSlice.reducer;