import api from "../../services/api";
import {
  authenticate,
  isAuthenticated,
  storeUserInfo,
} from "../../services/auth";
import endPoints from "../../services/endPoints";
import { setUserProfile } from "../slices/userSlice";

const onUserLoginAction = (data, loading, navigate, onError) => async (dispatch) => {
  let payload = { email: data.email, password: data.loginPassword };
  loading(true);
  const res = await api("post", endPoints.LOGIN, payload);
  if (res.success) {
    let user = res?.data?.results?.user;
    user.rememberMe = data.rememberMe
    let token = res.data.results?.access_token;
    storeUserInfo(user, data.rememberMe);
    authenticate(token, data.rememberMe, () => {
      dispatch(setUserProfile(user));
      if (isAuthenticated()) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    });
  } else {
    onError(res.message);
  }
  loading(false);
};

const onUserSignUpAction = (data, loading, navigate, onRes) => async () => {
  let payload = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    password: data?.password,
    confirm_password: data?.confirmPassword,
    email: data?.businessEmail,
  };
  loading(true);
  const res = await api("post", endPoints.REGISTER, payload);
  onRes(res);
  loading(false);
};

const verifyTokenAction = (token, onRes) => async () => {
  const res = await api("get", endPoints.VERIFY_TOKEN + `?${token}`);
  onRes(res)
};

const setupAccountAction = (data, token, loading, navigate, onError) => async () => {
  let payload = {
    first_name: data?.firstName,
    last_name: data?.lastName,
    password: data?.password,
    confirm_password: data?.confirmPassword,
    phone_number: data?.phoneNumber,
  };
  loading(true);
  const res = await api("post", endPoints.SETUP_ACCOUNT + `?${token}`, payload);
  if (res?.success) {
    navigate("/")
  } else {
    onError(res?.message)
  }
  loading(false);
};

const onOtpVerification = (data, loading, onRes) => async () => {
  let payload = {
    email: data?.email,
    otp: data?.otp,
  };

  loading(true);
  const res = await api(
    "post",
    endPoints?.VERIFY_OTP + `${data?.action}/`,
    payload
  );
  onRes(res);
  loading(false);
};

const onSendOtpAction = (payload, loading, onRes) => async () => {
  if (loading) {
    loading(true);
  }
  const res = await api(
    "post",
    endPoints.SEND_OTP + `${payload?.action}/`,
    payload
  );
  onRes(res);
  if (loading) {
    loading(false);
  }
};

const onCreateNewPasswordAction = (data, loading, onRes) => async () => {
  let payload = {
    email: data?.email,
    password: data?.password,
    confirm_password: data?.confirmPassword,
  };

  loading(true);
  const res = await api("post", endPoints?.CHANGE_FORGOT_PASSWORD, payload);
  onRes(res);
  loading(false);
};

const getProfileAction = () => async (dispatch) => {
  const res = await api("get", endPoints.PROFILE);
  if (res.success) {
    storeUserInfo(res.data);
    dispatch(setUserProfile(res.data));
  }
};

export {
  onUserLoginAction,
  onUserSignUpAction,
  onOtpVerification,
  onSendOtpAction,
  onCreateNewPasswordAction,
  getProfileAction,
  setupAccountAction,
  verifyTokenAction
};
