import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import classNames from "classnames";

export default function SidebarItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const SVG = props.Svg;
  const _title = props.title;
  const _items = props.items;
  const _onClick = props.onClick;
  const _link = props.link;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const onItemClick = () => {
    if (_items && _items.length) {
      handleToggle();
      return;
    }
    if (_link) {
      navigate(_link);
      return;
    }

    if (_onClick) {
      _onClick();
      return;
    }
  };

  const getActive = (path) => {
    return path === location.pathname;
  };
  return (
    <>
      <div
        className={classNames("sidebar-item flex justify-content-between", {
          active: isOpen || getActive(_link),
        })}
        onClick={onItemClick}
      >
        <div className="flex">
          <SVG />
          &nbsp; &nbsp;
          <div>{_title}</div>
        </div>
        {_items && _items.length && (
          <>
            {/* <i
              className={classNames("open-icon pi pi-caret-right my-auto", {
                open: isOpen,
              })}
            ></i> */}
            {/* <div
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
                // display: "inline-block",
              }}
            > */}
            <svg
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
                // display: "inline-block",
              }}
              width="25"
              height="25"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1117 11.8978L5.19629 6.98242L15.0271 6.98242L10.1117 11.8978Z"
                fill="white"
              />
            </svg>
            {/* </div> */}
          </>
        )}
      </div>

      {isOpen && (
        <div className="menu-items">
          {_items.map((item) => (
            <MenuItem
              Svg={item?.icon}
              title={item?.title}
              items={item?.items}
              link={item?.link ? _link + item?.link : _link}
            />
          ))}
        </div>
      )}
    </>
  );
}
