import { setCustomerSlice } from "store/slices/CustomerSlices";
import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";

const addCustomerAction = (data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        email: data?.email,
        first_name: data?.firstName,
        last_name: data?.lastName,
    };

    const res = await api(
        "post",
        endPoints?.CREATE_CUSTOMER,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)

};

const getCustomersListAction = (rows, page, search, name, email, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.COMPANY_USERS + `?limit=${rows}&page=${page}&search=${search || ""}&name=${name || ""}&email=${email || ""}`,
    );

    if (res?.success) {
        const options = res?.data?.results?.map((item) => {
            return {
                ...item,
                name: item?.first_name + " " + item?.last_name
            }
        })

        onRes({ options: options, count: res?.data?.count || res?.data?.results?.length })
    }
};

const getCustomerDetailAction = (id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.CUSTOMER + `/${id}`,
    );

    if (res?.success) {
        onRes(res?.data?.results)
        dispatch(setCustomerSlice(res?.data?.results))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const blockCustomerAction = (company, dispatch, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.BLOCK_CUSTOMER + `/${company}/`,
    );
    onRes(res)
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};

const unblockCustomerAction = (company, dispatch, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.UNBLOCK_CUSTOMER + `/${company}/`,
    );
    onRes(res)
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
};


export {
    addCustomerAction,
    blockCustomerAction,
    getCustomersListAction,
    getCustomerDetailAction,
    unblockCustomerAction
}