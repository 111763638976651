import React, { useRef } from "react";
import InputLayout from "./InputLayout";
import { Chips } from "primereact/chips";
import { formatFieldName, truncateText } from "../utils/regex";
import {
  convertAmountInGerman,
  getFileExtension,
  getFileName,
} from "../utils/commonFunctions";
import constants from "../constants";
import pdfIcon from "../assets/images/icons/pdfIcon.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { Tooltip } from "primereact/tooltip";

const baseURL = constants.baseUrl;
export const CustomViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  icon,
  ignoreLabel,
  ...props
}) => {
  let valToShow = value || data?.[name];

  if (valToShow && isNaN(valToShow)) {
    valToShow = formatFieldName(valToShow);
  }
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      ignoreLabel={ignoreLabel}
    >
      {icon ? icon : ""}
      {valToShow || "-----"} {valToShow ? spanLabel : null}
    </InputLayout>
  );
};

export const CustomImageView = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  image,
  src,
  ...props
}) => {
  
  const view = useRef(null);
  const extension = getFileExtension(image);
  const imageName = getFileName(image);
  const openImage = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  return (
    <>
      <div className="relative">
        {extension === "pdf" ? (
          <>
            <img className="image-view" src={pdfIcon} alt="" />
            <Tooltip
              className="tooltip"
              target={`.tooltip-pdf`}
              content={imageName}
            />
            <p
              className={`tooltip-pdf cursor-pointer`}
              data-pr-classname="tooltip"
            >
              {truncateText(imageName, 10)}
            </p>
          </>
        ) : src ? (
          <img className="image-view" src={URL.createObjectURL(src)} alt="" />
        ) : (
          <img className="image-view" src={`${baseURL}${image}`} alt="" />
        )}
        {image && (
          <i
            className="pi pi-ellipsis-v absolute text-black cursor-pointer"
            style={{ top: "8px", right: "-15px" }}
            onClick={(e) => view.current.toggle(e)}
          ></i>
        )}
        <OverlayPanel ref={view}>
          <ul className="list-none p-0">
            <li
              className="p-2"
              onClick={() => openImage(baseURL + image)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-eye"></i> View
            </li>
            <li
              className="p-2"
              onClick={() => saveAs(baseURL + image, imageName)}
              style={{ cursor: "pointer" }}
            >
              <i className="pi pi-download"></i> Download
            </li>
          </ul>
        </OverlayPanel>
      </div>
    </>
  );
  // }
};

export const CustomNumberViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {icon && value ? icon : ""}
      {value ? value : data?.[name] ? data?.[name] : "-----"}
    </InputLayout>
  );
};

export const CustomViewArrayInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  let totalLength = data?.[name]?.length - 1;
  let dataToView = data?.[name]?.map((info, index) => {
    if (index < totalLength) {
      return `${info}, `;
    } else {
      return info;
    }
  });

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {dataToView || "-----"}
    </InputLayout>
  );
};

export const CustomViewChips = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={"Email"}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {data?.[name] ? (
        <Chips className={`w-full mt-2 chips`} value={data?.[name]} disabled />
      ) : (
        "-----"
      )}
    </InputLayout>
  );
};

export const CustomAmountViewInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  icon,
  ...props
}) => {
  const val = value || data?.[name];
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
    >
      {icon && value ? icon : ""}
      {val ? convertAmountInGerman(val) : "-----"}
    </InputLayout>
  );
};
