import React, { useEffect, useState } from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomForm, CustomSwitch } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import OfficeView from "views/CommonViewComponents/OfficeView";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  blockCustomerAction,
  getCustomerDetailAction,
  unblockCustomerAction,
} from "store/actions/CustomerActions";
import { getOfficeDetailAction } from "store/actions/officeActions";

export default function ViewCustomer() {
  const dispatch = useDispatch();
  const params = useParams();
  // const [isBlocked, setIsBlocked] = useState(false);
  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    isBlocked: "",
    company: "",
  });
  const { customer } = useSelector((state) => state?.customer);
  const [officeData, setOfficeData] = useState({
    officeId: "",
    officeName: "",
    officeNickName: "",
    officeEmail: "",
    phoneNumber: "",
    unit: "",
    // officePurpose: "",
    houseNo: "",
    address: "",
    street: "",
    zipCode: "",
    city: "",
    cityDivision: "",
    country: "",
    costCenter: "",
    bankAccounts: "",
    officeEmployees: "",
  });

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <CustomSwitch
          name="isBlocked"
          data={data}
          onChange={handleChange}
          label={data?.isBlocked ? "Blocked" : "Unblocked"}
        />
      </div>
    );
  };

  const handleChange = ({ name, value }) => {
    if (value) {
      dispatch(
        blockCustomerAction(data?.company, dispatch, (res) => {
          if (res.success) {
            setData((prev) => ({ ...prev, [name]: value }));
          }
        })
      );
    } else {
      dispatch(
        unblockCustomerAction(data?.company, dispatch, (res) => {
          if (res.success) {
            setData((prev) => ({ ...prev, [name]: value }));
          }
        })
      );
    }
  };

  useEffect(() => {
    if (customer?.id) {
      setData({
        email: customer?.email,
        firstName: customer?.first_name,
        lastName: customer?.last_name,
        isBlocked: customer?.is_blocked,
        company: customer?.company,
      });
      getOfficeDetails(customer?.company);
    } else if (!customer?.id && params?.id) {
      getCustomerDetail(params?.id);
    }
  }, [params?.id, customer]);

  const getCustomerDetail = (id) => {
    dispatch(
      getCustomerDetailAction(id, dispatch, (res) => {
        setData({
          email: customer?.email,
          firstName: customer?.first_name,
          lastName: customer?.last_name,
          isBlocked: customer?.is_blocked,
          company: customer?.company,
        });
      })
    );
  };

  const getOfficeDetails = (id) => {
    dispatch(
      getOfficeDetailAction(id, dispatch, (res) => {
        setOfficeData({
          officeId: res?.office_id,
          officeName: res?.name,
          officeNickName: res?.nick_name,
          officeEmail: res?.office_email,
          phoneNumber: res?.phone_number,
          unit: res?.office_unit,
          // officePurpose: res?.purpose,
          houseNo: res?.house_number,
          address: res?.address,
          street: res?.street,
          zipCode: res?.zipcode,
          city: res?.city,
          cityDivision: res?.city_division,
          country: res?.country,
          costCenter: res?.cost_center,
          officeRep: res?.office_rep,
          bankAccounts: res?.bank_accounts || [],
        });
      })
    );
  };

  return (
    <Page
      title={`View Customer`}
      description={`Let’s View your Customer Details`}
      navigateLink={"/customers/list"}
    >
      <CustomCard>
        <CustomForm title="Customer" header={header()} col={4} titleCol={8}>
          <CustomViewInput name="email" data={data} />
          <CustomViewInput name="firstName" data={data} />
          <CustomViewInput name="lastName" data={data} />
        </CustomForm>
        <OfficeView data={officeData} />
      </CustomCard>
    </Page>
  );
}
