import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";

const getOfficeDetailAction = (id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE + `/${id}`,
    );

    if (res?.success) {
        onRes(res?.data?.results)
    }
};

export {
    getOfficeDetailAction
}