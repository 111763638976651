import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const buildingSlice = createSlice({
    name: "building",
    initialState,
    reducers: {
        setBuildingSlice: (state, action) => {
            state.building = action.payload
        },
    },

});

export const { setBuildingSlice } = buildingSlice.actions;
export default buildingSlice.reducer;