import React from "react";
import { CustomForm, CustomInput } from "shared/AllInputs";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";

import CustomerCotainer from "./CustomerContainer";

const CustomerForm = ({ type }) => {
  const { data, handleChange, onSubmit, loading, navigate } =
    CustomerCotainer();

  return (
    <Page
      title={`${type} Customer`}
      description={`Let’s ${type} your Customer Details`}
      navigateLink={"/customers/list"}
    >
      <CustomCard>
        <CustomForm title="Customer">
          <CustomInput
            data={data}
            onChange={handleChange}
            name="email"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="firstName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="lastName"
            required
          />
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label={type === "Edit" ? "Save Changes" : "Save"}
          loading={loading}
        />
        <CustomButtonOutlined
          label="Cancel"
          onClick={() => navigate("/customers/list")}
        />
      </Buttonlayout>
    </Page>
  );
};

export default CustomerForm;
