import React, { useState, useEffect, useRef } from "react";
import Layout from "./Layout";
import { CustomForm, CustomOtpInput } from "../shared/AllInputs";
import CustomButton from "../shared/CustomButton";
import { useDispatch } from "react-redux";
import {
  onOtpVerification,
  onSendOtpAction,
} from "../store/actions/userActions";
import { useNavigate } from "react-router-dom";
import { Messages } from "primereact/messages";
import formValidation from "../utils/validations";
import { showFormErrors } from "../utils/commonFunctions";
import { capitalFirstLetter } from "../utils/regex";
import { Toast } from "primereact/toast";

const OTPVerification = ({ signup, action, email, setOtpVerified }) => {
  const msgs = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(60);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    otpCode: "",
  });

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timer > 0) {
        let newTime = timer - 1;
        setTimer(newTime);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [timer]);

  const onResendOTP = async () => {
    if (!timer) {
      let data = {
        action: action,
        email: email,
      };
      dispatch(
        onSendOtpAction(data, null, (res) => {
          if (res?.success) {
            msgs.current.show({
              severity: "success",
              detail: res?.message,
            });
            setTimer(60);
          } else {
            msgs.current.show({
              severity: "error",
              detail: res?.message,
            });
          }
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      let payload = {
        action: action,
        email: email,
        otp: data?.otpCode,
      };
      dispatch(
        onOtpVerification(payload, setLoading, (res) => {
          if (res?.success) {
            action === "verify"
              ? navigate("/")
              : setOtpVerified && setOtpVerified(true);
          } else {
            msgs.current.show({
              severity: "error",
              detail: res?.message,
            });
          }
        })
      );
    }
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  return (
    <Layout title="We have sent a code to your email" signup={signup}>
      <CustomForm>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <div className="text-dark-gray">
          Enter the 6-digit verification code sent to{" "}
          <span className="font-semibold">{capitalFirstLetter(email)}</span>
        </div>
        <CustomOtpInput
          data={data}
          name="otpCode"
          label="OTP Code"
          onChange={handleChange}
        />
        <CustomButton
          onClick={onSubmit}
          loading={loading}
          extraClassNames="w-full my-4"
          label="Verify"
        />
      </CustomForm>
      <div className="col-12">
        <p className=" font-14 mb-3">
          Didn’t receive the code!{" "}
          <span className="link cursor-pointer" onClick={onResendOTP}>
            Resend
          </span>{" "}
          in {timer + "s"}
        </p>
        <p className=" font-14 mb-3">
          If you don’t see the email in your inbox, check your spam folder.
        </p>
      </div>
    </Layout>
  );
};

export default OTPVerification;
