import React, { useEffect, useRef, useState } from "react";
import formValidation from "../utils/validations";
import {
  CustomForm,
  CustomInput,
  CustomPassword,
  CustomPhoneInput,
} from "../shared/AllInputs";
import CustomCard from "../shared/CustomCard";
import CustomButton, { Buttonlayout } from "../shared/CustomButton";
import { showFormErrors } from "../utils/commonFunctions";
import { Messages } from "primereact/messages";
import { useDispatch } from "react-redux";
import {
  setupAccountAction,
  verifyTokenAction,
} from "../store/actions/userActions";
import { ReactComponent as Logo } from "../assets/images/svg/logo1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";

const SetupAcount = () => {
  const msgs = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (params?.token) {
      VerifyToken(params?.token);
    }
  }, [params]);

  const VerifyToken = (token) => {
    dispatch(
      verifyTokenAction(token, (res) => {
        if (res?.success) {
          setUserEmail(res?.data?.results?.email);
        } else {
          msgs.current.show({
            severity: "error",
            detail: res?.message,
          });
          navigate("/");
        }
      })
    );
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (showFormErrors(data, setData)) {
      dispatch(
        setupAccountAction(
          data,
          params?.token,
          setLoading,
          navigate,
          (error) => {
            msgs.current.show({
              severity: "error",
              detail: error,
            });
          }
        )
      );
    }
  };
  //123-lSYIWEmlqM9x6SU6Z9fQ
  return (
    <>
      <div className="layout-wrapper">
        <div className="layout-topbar px-4">
          <div className="flex">
            <Logo />
          </div>
        </div>
        <div className="layout-main-container">
          <div className={`layout-main-box `}>
            <div className="wrapper-auth h-full py-6 w-10 m-auto">
              <div className="container relative ">
                <h3 className="text-center text-4xl text-black fw-bold mb-3">
                  Setup Your Account
                </h3>

                <div className="form-step" id="step-1">
                  <div className="text-center mb-5">
                    <p className="text-gray-color fw-medium">
                      Hii <span className="font-bold">{userEmail}</span> Let’s
                      setup your Account
                    </p>
                  </div>
                </div>
              </div>
              <CustomCard>
                <CustomForm className="form">
                  {/* <Messages className="w-full" ref={msgs} /> */}
                  <Toast ref={msgs} />
                  <CustomInput
                    data={data}
                    onChange={handleChange}
                    name="firstName"
                    required
                  />
                  <CustomInput
                    data={data}
                    onChange={handleChange}
                    name="lastName"
                    required
                  />
                  <CustomPhoneInput
                    data={data}
                    onChange={handleChange}
                    name="phoneNumber"
                    required
                  />
                  <CustomPassword
                    data={data}
                    onChange={handleChange}
                    name="password"
                    required
                    col="6"
                  />{" "}
                  <CustomPassword
                    data={data}
                    onChange={handleChange}
                    name="confirmPassword"
                    required
                    col="6"
                  />
                </CustomForm>
              </CustomCard>
              <Buttonlayout>
                <CustomButton
                  onClick={onSubmit}
                  label={"Save"}
                  type="button"
                  loading={loading}
                ></CustomButton>
              </Buttonlayout>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupAcount;
