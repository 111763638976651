import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function CommonViewTable({
  title,
  list,
  columns,
  actions,
  header,
}) {
  return (
    <CustomForm title={title} header={header && header()} titleCol={8} col={4}>
      <DataTable
        className="col-12"
        value={list}
        tableStyle={{
          minWidth: "50rem",
        }}
      >
        {columns?.map((col, index) => {
          return (
            <Column
              key={index}
              className="firstletter_capital"
              field={col.accessor}
              header={col?.name}
              headerClassName="custom-header"
              body={col.body}
            ></Column>
          );
        })}
        {actions && (
          <Column
            body={actions}
            style={{ width: "80px" }}
            header="Action"
            headerClassName="custom-header"
          ></Column>
        )}
      </DataTable>
    </CustomForm>
  );
}
