import Dashboard from "../views/Dashboard";
import CustomerList from "views/Customers/CustomersList";
import AddCustomer from "views/Customers/CustomerForms/AddCustomer";
import ViewCustomer from "views/Customers/CustomerForms/ViewCustomer";

export const PrivateRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
  },

  {
    path: "/customers/list",
    name: "Customer List",
    element: <CustomerList />,
  },
  {
    path: "/customers/add",
    name: "Add Customer",
    element: <AddCustomer />,
  },
  {
    path: "/customers/view/:id",
    name: "View Customer",
    element: <ViewCustomer />,
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   element: <Reports />,
  // },
  // {
  //   path: "/tenants/list",
  //   name: "Tenants List",
  //   element: <TenantsList />,
  // },
  // {
  //   path: "/tenants/add",
  //   name: "Add Tenant",
  //   element: <AddTenant />,
  // },
  // {
  //   path: "/administrator/office-unit/list",
  //   name: "Office Unit List",
  //   element: <OfficeUnitList />,
  // },
  // {
  //   path: "/administrator/office-unit/add",
  //   name: "Add Office Unit",
  //   element: <AddOfficeUnit />,
  // },
  // {
  //   path: "/administrator/office-unit/view/:id",
  //   name: "Add Office Unit",
  //   element: <ViewOfficeUnit />,
  // },
  // {
  //   path: "/administrator/office-unit/edit/:id",
  //   name: "Add Office Unit",
  //   element: <EditOfficeUnit />,
  // },
  // {
  //   path: "/administrator/office/list",
  //   name: "Office Unit List",
  //   element: <OfficeList />,
  // },
  // {
  //   path: "/administrator/office/view/:id",
  //   name: "Add Office Unit",
  //   element: <ViewOffice />,
  // },
  // {
  //   path: "/administrator/office/add",
  //   name: "Add Office Unit",
  //   element: <AddOffice />,
  // },
  // {
  //   path: "/administrator/office/edit/:id",
  //   name: "Add Office Unit",
  //   element: <EditOffice />,
  // },
  // {
  //   path: "/administrator/employees/list",
  //   name: "Office Unit List",
  //   element: <EmployeesList />,
  // },
  // {
  //   path: "/administrator/employees/add",
  //   name: "Add Office Unit",
  //   element: <AddEmployee />,
  // },
  // {
  //   path: "/administrator/employees/view/:id",
  //   name: "Add Office Unit",
  //   element: <ViewEmployee />,
  // },
  // {
  //   path: "/administrator/employees/edit/:id",
  //   name: "Add Office Unit",
  //   element: <EditEmployee />,
  // },
  // {
  //   path: "/administrator/roles/list",
  //   name: "Roles List",
  //   element: <RolesList />,
  // },
  // {
  //   path: "/administrator/roles/add",
  //   name: "Add Role ",
  //   element: <AddRole />,
  // },
  // {
  //   path: "/administrator/roles/view/:id",
  //   name: "View Role ",
  //   element: <ViewRole />,
  // },
  // {
  //   path: "/administrator/roles/edit/:id",
  //   name: "Edit Role ",
  //   element: <EditRoles />,
  // },

  // {
  //   path: "/real-estate/property/list",
  //   name: "Property List",
  //   element: <PropertyList />,
  // },
  // //add property
  // {
  //   path: "/real-estate/property/add/building-structure",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // {
  //   path: "/real-estate/property/add/building-unit",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // {
  //   path: "/real-estate/property/add/landlord-info",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // {
  //   path: "/real-estate/property/add/contractual-info",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // {
  //   path: "/real-estate/property/add/rent-and-cost",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // {
  //   path: "/real-estate/property/add/annual-statement",
  //   name: "Add Property",
  //   element: <AddProperty />,
  // },
  // //view property
  // {
  //   path: "/real-estate/property/view/property/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/building-structure/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/building-unit/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/landlord-info/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/contractual-info/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/rent-and-cost/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/view/annual-statement/:id",
  //   name: "View Property",
  //   element: <ViewEditProperty />,
  // },

  // //edit property
  // {
  //   path: "/real-estate/property/edit/property/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/building-structure/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/building-unit/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/landlord-info/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/contractual-info/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/rent-and-cost/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },
  // {
  //   path: "/real-estate/property/edit/annual-statement/:id",
  //   name: "Edit Property",
  //   element: <ViewEditProperty />,
  // },

  // //contract
  // {
  //   path: "/real-estate/property/contract/list",
  //   name: "Contract List",
  //   element: <ContractList />,
  // },
  // {
  //   path: "/real-estate/property/contract/add",
  //   name: "Add Contract",
  //   element: <AddContract />,
  // },
  // {
  //   path: "/real-estate/property/contract/view/:id",
  //   name: "View Contract",
  //   element: <ViewContractForm />,
  // },
  // {
  //   path: "/real-estate/property/contract/view/:id/:page",
  //   name: "View Contract",
  //   element: <ViewContractForm />,
  // },
  // {
  //   path: "/real-estate/property/contract/edit/:id",
  //   name: "Edit Contract",
  //   element: <EditContract />,
  // },

  // //Building
  // {
  //   path: "/real-estate/property/building/list",
  //   name: "Contract List",
  //   element: <BuildingList />,
  // },
  // {
  //   path: "/real-estate/property/building/add",
  //   name: "Edit Contract",
  //   element: <AddBuilding />,
  // },
  // {
  //   path: "/real-estate/property/building/add",
  //   name: "Add Building",
  //   element: <AddBuilding />,
  // },
  // {
  //   path: "/real-estate/property/building/view/:id",
  //   name: "View Building",
  //   element: <ViewBuilding />,
  // },
  // {
  //   path: "/real-estate/property/building/edit/:id",
  //   name: "Edit Building",
  //   element: <EditBuilding />,
  // },
  // //Landlord
  // {
  //   path: "/real-estate/landlord/list",
  //   name: "Landlord List",
  //   element: <LandlordList />,
  // },
  // {
  //   path: "/real-estate/landlord/add",
  //   name: "Add Landlord",
  //   element: <AddLandlord />,
  // },
  // {
  //   path: "/real-estate/landlord/edit/:id",
  //   name: "Edit Landlord",
  //   element: <EditLandlord />,
  // },
  // {
  //   path: "/real-estate/landlord/view/:id",
  //   name: "View Landlord",
  //   element: <ViewLandlord />,
  // },

  // //Handyman
  // {
  //   path: "/settings/handyman-services",
  //   name: "Handyman Services",
  //   element: <HandymanServiceList />,
  // },
  // {
  //   path: "/real-estate/maintenance/handyman/list",
  //   name: "Handyman List",
  //   element: <HandymanList />,
  // },
  // {
  //   path: "/real-estate/maintenance/handyman/add",
  //   name: "Add Handyman",
  //   element: <AddHandyman />,
  // },
  // {
  //   path: "/real-estate/maintenance/handyman/view/:id",
  //   name: "View Handyman",
  //   element: <ViewHandyman />,
  // },
  // {
  //   path: "/real-estate/maintenance/handyman/edit/:id",
  //   name: "Edit Handyman",
  //   element: <EditHandyman />,
  // },

  // //damage report
  // {
  //   path: "/real-estate/maintenance/damage/list",
  //   name: "Damage List",
  //   element: <DamageReportList />,
  // },
  // {
  //   path: "/real-estate/maintenance/damage-report/add",
  //   name: "Add Damage",
  //   element: <AddDamageReport />,
  // },
  // {
  //   path: "/real-estate/maintenance/damage-report/view/:id",
  //   name: "View Damage",
  //   element: <ViewDamageReport />,
  // },

  // //damage
  // {
  //   path: "/real-estate/maintenance/damage-report/damage/edit/:id",
  //   name: "Damage History",
  //   element: <AddDamageForm />,
  // },
  // // {
  // //   path: "/real-estate/maintenance/damage/report-damage",
  // //   name: "Damage History",
  // //   element: <AddDamage />,
  // // },

  // //damage history
  // {
  //   path: "/real-estate/maintenance/damage/damage-history/:id",
  //   name: "Damage History",
  //   element: <DamageHistory />,
  // },
  // {
  //   path: "/real-estate/maintenance/damage-report/advance",
  //   name: "Damage History",
  //   element: <AdvancedFilterDemo />,
  // },

  // //maintenance
  // {
  //   path: "/real-estate/maintenance/list",
  //   name: "Maintenance List",
  //   element: <MaintenaceList />,
  // },
  // {
  //   path: "/real-estate/maintenance/view/:id",
  //   name: "Maintenance List",
  //   element: <ViewMaintenance />,
  // },

  // //annualstatement
  // {
  //   path: "/real-estate/annualstatement/list",
  //   name: "Maintenance List",
  //   element: <AnnualStatementList />,
  // },
  // {
  //   path: "/real-estate/annualstatement/add",
  //   name: "Add Maintenance",
  //   element: <AddAnnualStatement />,
  // },
  // {
  //   path: "/real-estate/annualstatement/view/:id",
  //   name: "View Maintenance",
  //   element: <ViewAnnualStatement />,
  // },
];
